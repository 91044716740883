import React, { useCallback, useMemo, useState } from 'react';
import { Col, Text, Table, Row, TouchField, Image, CheckBox, modal } from 'components';
import { COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import Store from 'store';
import shallow from 'zustand/shallow';
import { TLearning } from 'type';

const ConfirmImportModal = ({ learnings }: { learnings: TLearning[] }) => {
  const [loading, setLoading] = useState(false);
  const [shouldUpdateExisting, setShouldUpdateExisting] = useState(false);
  const { getPathfinders } = Store.usePathfinderStore((state) => ({
    getPathfinders: state.getPathfinders,
  }), shallow);
  const { getLearnings } = Store.useLearningStore(state => ({
    getLearnings: state.getLearnings,
  }), shallow);
  const { selectedClient } = Store.useClientStore();

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      await Store.Api.Learning.import({
        learnings,
        updateExisting: shouldUpdateExisting,
        clientId: selectedClient?.id,
      });
      getPathfinders(selectedClient?.id);
      getLearnings(selectedClient?.id);
      modal.hide();
    } catch (error) {
      alert(error?.message || error);
    } finally {
      setLoading(false);
    }
  }, [shouldUpdateExisting, selectedClient])

  const data = useMemo(() => {
    if (!learnings?.length) return [];
    return learnings.map(i => ({
      ...i,
      url: <a target='_blank' style={{ color: "blue", fontSize: 14 }} href={i.url}>{i.urlShortName || i.url}</a>,
      logo: <Image source={{ uri: i.icon }} style={{ width: 70, height: 70 }} resizeMode='contain' />,
    }))
  }, [learnings])

  return (
    <Col round1 bgWhite shadow p2>
      <Text fontSize={16} semiBold mb2>Confirm Import</Text>
      <Table
        columns={[
          { key: 'logo', title: 'Icon', width: 95, padding: 10 },
          { key: 'name', title: 'Name', flex: 1 },
          { key: 'url', title: 'URL', flex: 1 },
          { key: 'tags', title: 'Tags', flex: 1 },
        ]}
        data={data}
        style={{ margin: 20 }}
        minWidthRequired={500}
      />
      <Text mt2 mb1>If the learning's name already exists, then:</Text>
      <Col>
        <Row>
          <CheckBox
            value={!shouldUpdateExisting}
            onChange={() => setShouldUpdateExisting(s => !s)}
          />
          <Text onPress={() => setShouldUpdateExisting(s => !s)}>Create new</Text>
        </Row>
        <Row>
          <CheckBox
            value={shouldUpdateExisting}
            onChange={() => setShouldUpdateExisting(s => !s)}
          />
          <Text onPress={() => setShouldUpdateExisting(s => !s)}>Update existing</Text>
        </Row>
      </Col>
      <Row justifyContent={'flex-end'} mt2>
        <TouchField
          height={40}
          width={80}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          onPress={() => modal.hide()}
          m0
          ph2
        >
          <Text color={COLOR.MAIN}>Cancel</Text>
        </TouchField>
        <TouchField
          height={40}
          ph2
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          backgroundColor={COLOR.MAIN}
          m0
          onPress={onSubmit}
        >
          <Row>
            {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
            <Text color={'white'}>Submit</Text>
          </Row>
        </TouchField>
      </Row>
    </Col>
  );
};

export default ConfirmImportModal;
