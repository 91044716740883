import { Col, Text, TouchField, Row, Image, Table, UploadLearningImage, modalConfirm, UploadFile, modal } from 'components';
import React, { useMemo, useRef, useState } from 'react';
import { COLOR } from 'const';
import { ActivityIndicator, ScrollView, useWindowDimensions } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import { useImageStore } from 'store/Image.Store';
import { Tooltip } from 'react-tippy';
import { TImage } from 'type';

const ImageUploader = (props: { clientId?: string, onSelect?: (image: TImage) => void }) => {
  const [loading, setLoading] = useState(false);
  const [randomId, setRandomId] = useState(0);
  const { height } = useWindowDimensions();
  const uploadRef = useRef<{ showDialog: Function }>(null);
  const updateRef = useRef<{ showDialog: Function }>(null);
  const { images, getImages, deleteImage, updateImageType } = useImageStore();

  const onPressUpload = () => {
    uploadRef.current.showDialog();
  }

  const onImageUploaded = async (urls) => {
    if (urls.length === 0) return setLoading(false);
    await getImages(props.clientId);
    setLoading(false);
  }

  const onImageUpdated = async (urls) => {
    if (urls.length === 0) return setLoading(false);
    setRandomId(Math.floor(Math.random() * 10000))
    setLoading(false);
  }

  const data = useMemo(() => {
    if (!images?.length) return [];

    const confirmDelete = (img) => {
      modalConfirm.show({
        title: 'Delete',
        message: 'Do you want to delete this icon?',
        onConfirm: async () => {
          await deleteImage(img.id);
        }
      })
    }

    const editLearningType = (img: TImage) => {
      modalConfirm.show({
        title: 'Confirm',
        message: 'Input learning type of the icon',
        isPrompt: true,
        initValue: img.type,
        onConfirm: async (type) => {
          if (!type || type === img.type) return;
          await updateImageType({ id: img.id, type });
        }
      })
    }

    return images.map(i => ({
      ...i,
      url: <Text numberOfLines={3} ellipsizeMode='middle'>{i.url}</Text>,
      image: props.onSelect ? (
        <TouchField middle onPress={() => props.onSelect(i)}>
          <Image source={{ uri: `${i.url}?rd=${randomId}` }} style={{ width: 80, height: 80 }} resizeMode='contain' />
        </TouchField>
      ) : (
        <TouchField middle onPress={() => updateRef.current?.showDialog(i.url)}>
          <Image source={{ uri: `${i.url}?rd=${randomId}` }} style={{ width: 80, height: 80 }} resizeMode='contain' />
          <Text color={COLOR.MAIN}>Edit</Text>
        </TouchField>
      ),
      actions: props.onSelect ? (
        <Row justifyContent='flex-end'>
          <TouchField
            onPress={() => props.onSelect(i)}
            padding={6}
            marginRight={4}
          >
            <Text color={COLOR.MAIN}>Select</Text>
          </TouchField>
        </Row>
      ) : (
        <Row justifyContent='flex-end'>
          <Tooltip title='Delete icon' trigger='mouseenter'>
            <TouchField
              onPress={() => confirmDelete(i)}
              padding={6}
              marginRight={4}
            >
              <FontAwesome5
                name={'trash'}
                size={22}
                color={COLOR.RED}
              />
            </TouchField>
          </Tooltip>
          <Tooltip title='Edit learning type' trigger='mouseenter'>
            <TouchField
              onPress={() => editLearningType(i)}
              padding={6}
              marginRight={4}
            >
              <FontAwesome5 name='pen' size={22} color={COLOR.MAIN} />
            </TouchField>
          </Tooltip>
          <Tooltip title='Copy link' trigger='mouseenter'>
            <TouchField
              onPress={() => navigator.clipboard.writeText(i.url)}
              padding={6}
            >
              <FontAwesome5
                name={'copy'}
                size={22}
                color={COLOR.MAIN}
              />
            </TouchField>
          </Tooltip>
        </Row>
      )
    }))
  }, [images, randomId, props.onSelect])

  return (
    <Col round1 bgWhite shadow p2 paddingBottom={0} height={height * 0.8}>
      <ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingBottom: 16 }} showsVerticalScrollIndicator={false}>
        <Row justifyContent={'space-between'} alignItems={'flex-end'} flexWrap={"wrap"} mb2>
          <Col>
            <Text h3 mb2 bold color={COLOR.MAIN}>Icon Uploader</Text>
            <Text fontSize={18}>Select images to upload and get URLs</Text>
          </Col>
          <TouchField
            height={40}
            ph2
            middle
            width={200}
            borderColor={COLOR.MAIN}
            borderWidth={1}
            backgroundColor={COLOR.MAIN}
            onPress={onPressUpload}
          >
            <Row>
              {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
              <Text color={'white'}>Select icon</Text>
            </Row>
          </TouchField>
        </Row>
        <UploadLearningImage
          isMulti
          ref={uploadRef}
          onUploaded={onImageUploaded}
          onUploading={() => setLoading(true)}
          accept="image/*"
        />
        <UploadFile
          ref={updateRef}
          onUploaded={onImageUpdated}
          onUploading={() => setLoading(true)}
        />
        <Table
          columns={[
            { key: 'image', title: 'Icon', width: 100, padding: 10 },
            { key: 'url', title: 'URL', flex: 1 },
            { key: 'type', title: 'Learning type', width: 150 },
            { key: 'actions', title: '', width: 140 },
          ]}
          data={data}
          style={{ margin: 20 }}
          minWidthRequired={500}
        />
        <Col absolute right={-8} top={-8}>
          <TouchField padding={8} onPress={() => modal.hide()}>
            <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
          </TouchField>
        </Col>
      </ScrollView>
    </Col>
  )
};

export default ImageUploader;
