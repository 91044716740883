import { FontAwesome5 } from "@expo/vector-icons";
import { Col, ImageSelect, Input, ModalContainer, Row, Text, TouchField, modal } from "components";
import { SelectTagInput } from "components/elements/form/Select";
import { COLOR } from "const";
import { VarHelper } from "helpers";
import { cloneDeep, isArray } from "lodash";
import React, { useCallback, useImperativeHandle, useMemo, useState } from "react";
import { ActivityIndicator, Modal, ScrollView, StyleSheet, useWindowDimensions } from "react-native";
import Store from "store";
import { TLearning } from "type";
import * as Animatable from 'react-native-animatable';

const fields = [
  { id: 'name', label: 'Name' },
  { id: 'url', label: 'URL' },
  { id: 'urlShortName', label: 'URL Short Name (optional)' },
  { id: 'comment', label: 'Comment (optional)' },
  { id: 'tags', label: 'Tags' },
  { id: 'type', label: 'Learning Type Text', sub: 'If no icon has been uploaded text will display' },
]

interface Props {
  data?: any
  title?: string
  refresh?: () => void
}

const LearningDetailModal = React.forwardRef<any, Props>(({ data, title, refresh }: Props, ref) => {
  const [fieldsData, setFieldsData] = useState<TLearning>(data || {})
  const [isVisible, setIsVisible] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { width, height } = useWindowDimensions()
  const [oldData, setOldData] = useState(data);
  const { learnings, updateLearning } = Store.useLearningStore();

  useImperativeHandle(ref, () => ({
    open: (data: any) => {
      setFieldsData(data || {});
      setOldData(data)
      setIsVisible(true);
    },
    close: () => {
      setIsVisible(false);
      // @ts-ignore
      setFieldsData({});
      setOldData(undefined);
    }
  }));

  const allTags = useMemo(() => {
    const result = [];
    if (!isArray(result)) return result;
    Object.keys(learnings).map(key => {
      const learning = learnings[key];
      learning?.tags?.split(',').forEach(key => {
        if (!result.includes(key.trim())) result.push(key.trim())
      })
    })
    return result.map(i => ({ label: i, value: i }));
  }, [learnings])

  const editField = useCallback((key, value) => {
    setFieldsData(state => ({
      ...state,
      [key]: value
    }));
  }, [])

  const requestClose = () => {
    setIsVisible(false);
    // @ts-ignore
    setFieldsData({});
    setOldData(undefined);
  }

  const onPressConfirm = useCallback(async () => {
    try {
      setLoading(true);
      let res;
      if (oldData) {
        const newData = VarHelper.removeUndefinedNullField({
          id: fieldsData.id,
          name: fieldsData.name,
          type: fieldsData.type,
          url: fieldsData.url,
          urlShortName: fieldsData.urlShortName,
          comment: fieldsData.comment,
          tags: fieldsData.tags,
          icon: fieldsData.icon,
        });
        res = await Store.Api.Learning.update(newData);
        updateLearning(fieldsData.id, newData);
      } else {
        res = await Store.Api.Learning.create(VarHelper.removeUndefinedNullField({
          name: fieldsData.name,
          type: fieldsData.type,
          url: fieldsData.url,
          urlShortName: fieldsData.urlShortName,
          comment: fieldsData.comment,
          tags: fieldsData.tags,
          icon: fieldsData.icon,
        }))
        if (fieldsData.icon) {
          await Store.Api.Image.create({
            name: `${fieldsData.name} icon`,
            url: fieldsData.icon,
            type: fieldsData.type,
          });
        }
      }
      if (!res.data.success) {
        setError(res.data.error);
        return;
      }
      setError('');
      refresh?.();
      requestClose();
    } catch (error) {
      setError(error?.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }, [fieldsData, data, oldData])

  return (
    <Modal
      visible={isVisible}
      transparent
      onRequestClose={() => setIsVisible(false)}
    >
      <ModalContainer skipMemo>
        <Animatable.View
          // @ts-ignore
          style={{
            ...StyleSheet.absoluteFillObject,
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.3)',
            flex: 1,
          }}
          animation='fadeIn'
          duration={300}
        >
          <Col flex1 middle onPress={() => setIsVisible(false)}>
            <Animatable.View
              animation='fadeInUp' duration={300} delay={300}
              // @ts-ignore
              style={{
                ...StyleSheet.absoluteFillObject,
                position: 'fixed',
                backgroundColor: 'rgba(0,0,0,0.3)',
                padding: 40,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Col onPress={() => { }} activeOpacity={1} maxHeight={height}>
                <ScrollView>
                  <Col padding={24} paddingBottom={12} paddingRight={16} borderRadius={8} minWidth={Math.min(500, width - 64)} bgWhite>
                    <Row alignItems='center' justifyContent='space-between' marginBottom={24}>
                      <Text fontSize={20} semiBold color={COLOR.MAIN}>{title}</Text>
                      <TouchField padding={4} onPress={requestClose}>
                        <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
                      </TouchField>
                    </Row>
                    {fields.map(field => {
                      if (field.id === 'tags') {
                        return (
                          <Col mt2 key={field.id}>
                            <Text caption mb1 bold>{field.label}</Text>
                            <SelectTagInput
                              options={allTags}
                              value={fieldsData.tags}
                              onChange={(opts) => editField('tags', opts.map(i => i.value).join(','))}
                              isMulti
                              bonusStyle={{
                                control: (style) => ({
                                  ...style,
                                  // height: 40,
                                  minHeight: 40,
                                  borderRadius: 8,
                                  backgroundColor: 'transparent',
                                  borderWidth: 1,
                                  borderColor: COLOR.BORDER_LIGHT,
                                  boxShadow: 'none',
                                  borderStyle: 'solid',
                                  '&:hover': {
                                    borderColor: COLOR.BORDER_LIGHT,
                                  },
                                }),
                              }}
                              noOptionsMessage='Type new tag and enter'
                              dataSet={{ element: 'select-tags' }}
                            />
                          </Col>
                        )
                      }
                      return (
                        <Col key={field.id} mt2>
                          <Text caption mb1={!field.sub} bold>{field.label}</Text>
                          {!!field.sub && <Text color={COLOR.SUB_TEXT} fontSize={12} mb1>{field.sub}</Text>}
                          <Input
                            height={40}
                            value={fieldsData[field.id]}
                            onChange={(v) => editField(field.id, v)}
                            inputProps={{
                              style: {
                                // @ts-ignore
                                outline: 'none',
                                fontSize: 16,
                              },
                            }}
                            borderRadius={8}
                            borderWidth={1}
                            borderColor={COLOR.BORDER_LIGHT}
                          />
                        </Col>
                      );
                    })}
                    <Col mt2>
                      <Text caption bold>Icon</Text>
                      <Text color={COLOR.SUB_TEXT} fontSize={12}>{`If no icon has been uploaded enter learning text above`}</Text>
                      <Text color={COLOR.SUB_TEXT} fontSize={12} mb2>Image dimensions: PNG - 150px - 150px</Text>
                      <Col width={150}>
                        <ImageSelect
                          value={fieldsData?.icon}
                          imageStyle={{ width: 150, height: 150 }}
                          onChange={(url) => editField('icon', url)}
                          isButtonsBottom
                          entityName="icon"
                          shortenUrl
                          allowSelectFromDB
                        />
                      </Col>
                    </Col>

                    {!!error &&
                      <Text color={COLOR.RED} marginTop={32}>{error}</Text>
                    }
                    <Row justifyContent={'flex-end'} marginTop={24}>
                      <TouchField
                        height={40}
                        width={80}
                        middle
                        borderColor={COLOR.MAIN}
                        borderRadius={20}
                        borderWidth={1}
                        onPress={requestClose}
                        m0 ph2
                      >
                        <Text color={COLOR.MAIN}>Cancel</Text>
                      </TouchField>
                      <TouchField
                        height={40}
                        middle
                        borderColor={COLOR.MAIN}
                        borderRadius={20}
                        borderWidth={1}
                        backgroundColor={COLOR.MAIN}
                        m0 ph2
                        onPress={onPressConfirm}
                      >
                        <Row>
                          {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
                          <Text color={COLOR.WHITE}>Save</Text>
                        </Row>
                      </TouchField>
                    </Row>
                  </Col>
                </ScrollView>
              </Col>
            </Animatable.View>
          </Col>
        </Animatable.View>
      </ModalContainer>
    </Modal>
  )
})

export default LearningDetailModal
