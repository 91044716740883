import React, { useRef } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { TouchField, modal } from "components/elements";
import { COLOR } from "const";
import { read, utils } from "xlsx";
import ConfirmImportModal from "./ConfirmImportModal";
import ExportHelper from "helpers/ExportHelper";

const ImportButton = () => {
  const inputRef = useRef(null);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = read(bstr, { type: 'binary' });
      const res = ExportHelper.excelToPathfinder(wb);
      modal.show(
        <ConfirmImportModal pathfinders={res.pathfinders} learnings={res.learnings} />
      );
    };
    reader.readAsBinaryString(file);

    e.target.value = '';
  };

  return (
    <TouchField
      width={36} height={36} marginLeft={8}
      borderRadius={18} middle
      onPress={() => inputRef.current?.click()}
      dataSet={{ element: 'import-pathfinder' }}
    >
      <MaterialCommunityIcons name='database-import' size={28} color={COLOR.MAIN} />
      <input
        ref={inputRef} type="file" accept=".xlsx"
        onChange={handleFileUpload} style={{ display: 'none' }}
      />
    </TouchField>
  )
}

export default ImportButton;
