import { write, utils, WorkBook } from "xlsx";
import { saveAs } from 'file-saver';
import { PathfinderImportItem } from "type";
import JSZip from 'jszip';

const fieldNames = {
  name: "Name",
  email: "Email",
  pathfinderName: "Pathfinder Name",
  statement: "Statement",
  likert: "Likert",
  createdAt: "Date Created",
  learnings: "Learnings",
};

const infoIdx = {
  name: {
    idx: 0,
    label: 'Name',
  },
  welcomeInstruction: {
    idx: 1,
    label: 'Welcome Instruction',
  },
  additionalInstruction: {
    idx: 2,
    label: 'Additional Instruction',
  },
  completionInstruction: {
    idx: 3,
    label: 'Completion Dialog Instructions',
  },
  likertScale: {
    idx: 4,
    label: 'Likert Scale',
  },
  elementsTitle: {
    idx: 5,
    label: 'Screen Element Title',
  },
}

const elementColIdx = {
  name: {
    idx: 0,
    label: 'Name',
  },
  rolloverText: {
    idx: 1,
    label: 'Rollover Text',
  },
  filters: {
    idx: 2,
    label: 'Filters',
  },
  image: {
    idx: 3,
    label: 'Image',
  },
}

const statementColIdx = {
  statement: {
    idx: 0,
    label: 'Statement',
  },
  rollover1: {
    idx: 1,
    label: 'Rollover 1',
  },
  rollover2: {
    idx: 2,
    label: 'Rollover 2',
  },
  rollover3: {
    idx: 3,
    label: 'Rollover 3',
  },
  element: {
    idx: 4,
    label: 'Element',
  },
  learning1: {
    idx: 5,
    label: 'Learnings 1',
  },
  learning2: {
    idx: 6,
    label: 'Learnings 2',
  },
  learning3: {
    idx: 7,
    label: 'Learnings 3',
  },
}

const learningColIdx = {
  name: {
    idx: 0,
    label: 'Learning Name',
  },
  url: {
    idx: 1,
    label: 'URL',
  },
  type: {
    idx: 2,
    label: 'Type',
  },
  icon: {
    idx: 3,
    label: 'Learning Icon',
  },
  comment: {
    idx: 4,
    label: 'Comment',
  },
  tags: {
    idx: 5,
    label: 'Tags',
  },
}

class ExportHelper {
  pathfinderToExcelBlob = (pathfinder: PathfinderImportItem) => {
    const workbook = utils.book_new();
    // Sheet 1: Pathfinder Info

    const infoData = []
    Object.keys(infoIdx).map((key) => {
      infoData[infoIdx[key].idx] = [infoIdx[key].label, pathfinder[key] || '']
    });
    const infoWorksheet = utils.aoa_to_sheet(infoData)
    infoWorksheet['!cols'] = [
      { wch: 30 }, // Label column
      { wch: 50 }, // Value column
    ];

    // Sheet 2: Elements
    const elementsData = pathfinder.elements.map(element => {
      const row = [];
      Object.keys(elementColIdx).forEach(key => {
        row[elementColIdx[key].idx] = element[key] || '';
      });
      return row;
    })
    const elementsWorksheet = utils.aoa_to_sheet([
      ['Note: If you change the name of an element, you must also change the name of the element in the Work Statements sheet.'],
      [''],
      ['Name', 'Rollover Text', 'Filters', 'Image'],
    ].concat(elementsData));
    elementsWorksheet['!cols'] = [
      { wch: 25 }, // Name
      { wch: 40 }, // Rollover Text
      { wch: 25 }, // Filters
      { wch: 25 }, // Image
    ];

    // Sheet 3: Learnings
    const learningsData = pathfinder.learnings.map(learning => {
      const row = [];
      Object.keys(learningColIdx).forEach(key => {
        row[learningColIdx[key].idx] = learning[key] || '';
      });
      return row;
    })
    const learningsWorksheet = utils.aoa_to_sheet([
      ['Note: If you change the name of a learning, you must also change the name of the learning in the Work Statements sheet.'],
      [''],
      ['Learning Name', 'URL', 'Type', 'Learning Icon', 'Comment', 'Tags'],
    ].concat(learningsData));
    learningsWorksheet['!cols'] = [
      { wch: 40 }, // Learning Name
      { wch: 25 }, // URL
      { wch: 25 }, // Type
      { wch: 25 }, // Learning Icon
      { wch: 25 }, // Comment
      { wch: 25 }, // Tags
    ];

    // Sheet 4: Work Statements
    const statementsData = pathfinder.statements?.map(statement => {
      const row = [];
      Object.keys(statementColIdx).forEach(key => {
        row[statementColIdx[key].idx] = statement[key] || '';
      });
      return row;
    }) || []
    const statementsWorksheet = utils.aoa_to_sheet([
      ['Work Statements'],
      ['Statement', 'Rollover 1', 'Rollover 2', 'Rollover 3', 'Element', 'Learnings 1', 'Learnings 2', 'Learnings 3'],
    ].concat(statementsData));
    statementsWorksheet['!cols'] = [
      { wch: 40 }, // Statement
      { wch: 25 }, // Rollover 1
      { wch: 25 }, // Rollover 2  
      { wch: 25 }, // Rollover 3
      { wch: 25 }, // Element
      { wch: 20 }, // Learnings 1
      { wch: 20 }, // Learnings 2
      { wch: 20 }, // Learnings 3
    ];

    // Add all sheets to workbook
    utils.book_append_sheet(workbook, infoWorksheet, 'Pathfinder Info');
    utils.book_append_sheet(workbook, elementsWorksheet, 'Elements');
    utils.book_append_sheet(workbook, learningsWorksheet, 'Learnings');
    utils.book_append_sheet(workbook, statementsWorksheet, 'Work Statements');

    const excelBuffer = write(workbook, { type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    return excelBlob;
  }

  saveDataToFile = (data: PathfinderImportItem[]) => {
    if (data.length === 1) {
      const excelBlob = this.pathfinderToExcelBlob(data[0]);
      saveAs(excelBlob, `exported-${data[0].name}.xlsx`);
    } else {
      const zip = new JSZip();
      data.forEach((pathfinder) => {
        const excelBlob = this.pathfinderToExcelBlob(pathfinder);
        zip.file(`${pathfinder.name}.xlsx`, excelBlob);
      });
      zip.generateAsync({ type: 'blob' }).then(zipBlob => {
        saveAs(zipBlob, 'exported-pathfinders.zip');
      });
    }
  }

  excelToPathfinder = (workbook: WorkBook) => {
    const pathfinders = [];
    const pathfinder: any = {};

    // Sheet 1: Pathfinder Info
    const infoSheet = workbook.Sheets['Pathfinder Info'];
    if (infoSheet) {
      const infoData = utils.sheet_to_json(infoSheet, { header: 1 });
      infoData.forEach(row => {
        if (row[0] && row[1]) {
          // Find the key by label
          const key = Object.keys(infoIdx).find(k => infoIdx[k].label === row[0]);
          if (key) {
            pathfinder[key] = row[1];
          }
        }
      });
    }

    // Sheet 2: Elements
    const elementsSheet = workbook.Sheets['Elements'];
    if (elementsSheet) {
      const elementsData = utils.sheet_to_json(elementsSheet, { header: 1 });
      console.log("elementsData", elementsData);
      pathfinder.elements = elementsData.slice(3).map(row => ({
        name: row[0] || '',
        rolloverText: row[1] || '',
        filters: row[2] || '',
        image: row[3] || ''
      })).filter(el => el.name);
    }

    // Sheet 3: Learnings
    const learningsSheet = workbook.Sheets['Learnings'];
    const learnings = [];
    if (learningsSheet) {
      const learningsData = utils.sheet_to_json(learningsSheet, { header: 1 });
      learningsData.slice(3).forEach(row => {
        if (row[0]) { // Only add if has name
          learnings.push({
            name: row[learningColIdx.name.idx] || '',
            url: row[learningColIdx.url.idx] || '',
            type: row[learningColIdx.type.idx] || '',
            icon: row[learningColIdx.icon.idx] || '',
            comment: row[learningColIdx.comment.idx] || '',
            tags: row[learningColIdx.tags.idx] ? String(row[learningColIdx.tags.idx]) : undefined
          });
        }
      });
    }

    // Sheet 4: Work Statements
    const statementsSheet = workbook.Sheets['Work Statements'];
    if (statementsSheet) {
      const statementsData = utils.sheet_to_json(statementsSheet, { header: 1 });
      pathfinder.statements = statementsData.slice(2).map(row => ({
        statement: row[statementColIdx.statement.idx] || '',
        rollover1: row[statementColIdx.rollover1.idx] || '',
        rollover2: row[statementColIdx.rollover2.idx] || '',
        rollover3: row[statementColIdx.rollover3.idx] || '',
        element: row[statementColIdx.element.idx] || '',
        learning1: row[statementColIdx.learning1.idx] || '',
        learning2: row[statementColIdx.learning2.idx] || '',
        learning3: row[statementColIdx.learning3.idx] || ''
      })).filter(st => st.statement);
    }

    // Support old format if new format sheets not found
    if (!infoSheet && !elementsSheet && !statementsSheet) {
      const res = this.excelToPathfinderOldFormat(workbook);
      pathfinders.push(...res.pathfinders);
      learnings.push(...res.learnings);
    } else {
      pathfinders.push(pathfinder);
    }
    return {
      pathfinders,
      learnings,
    }
  }

  excelToPathfinderOldFormat = (wb: WorkBook) => {
    const _infoIdx = {
      0: 'name',
      1: 'welcomeInstruction',
      2: 'additionalInstruction',
      3: 'completionInstruction',
      4: 'likertScale',
      5: 'elementsTitle',
    }
    const _elementColIdx = {
      name: 3,
      rolloverText: 4,
      filters: 5,
      image: 6,
    }
    const _statementColIdx = {
      statement: 7,
      rollover1: 8,
      learning1: 9,
      rollover2: 10,
      learning2: 11,
      rollover3: 12,
      learning3: 13,
      element: 14,
    }
    const _learningColIdx = {
      name: 0,
      url: 1,
      type: 2,
      icon: 3,
      comment: 4,
      tags: 5,
    }
    const parseImageUrl = (str: string) => {
      if (!str) return '';
      return String(str).match(/IMAGE\("(.*?)"/)?.[1] || str;
    }
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const range = utils.decode_range(ws['!ref']);

    let i = 0;
    const pathfinders = [];

    const getTargetPathfinder = (rowIdx: number) => {
      for (let i = 0; i < pathfinders.length; i++) {
        if (pathfinders[i].range[0] <= rowIdx && pathfinders[i].range[1] >= rowIdx) {
          return {
            pathfinder: pathfinders[i],
            idx: i,
          }
        }
      }
    }

    const grid = [];
    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      i += 1;
      const column = [];
      // Parse each row of the column
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        const cellAddress = utils.encode_cell({ r: rowNum, c: colNum });
        const cellValue = ws[cellAddress]?.v || ws[cellAddress]?.f;
        column.push(cellValue);
      }
      grid.push(column);

      // handle pathfinder logo
      if (i === 1) {
        let lastStartIdx;
        let lastLogo;
        for (let j = 0; j < column.length; j++) {
          if (column[j] === "Logo" || j === column.length - 1) {
            if (lastStartIdx !== undefined) {
              pathfinders.push({
                range: [lastStartIdx, j - 1],
                logo: lastLogo,
              });
            }
            lastLogo = undefined;
            lastStartIdx = j;
          }
          if (lastStartIdx !== undefined && j === lastStartIdx + 1) {
            lastLogo = parseImageUrl(column[j]);
          }
        }
      }
      // handle pathfinder infos
      if (i === 3) {
        for (let j = 0; j < column.length; j++) {
          const target = getTargetPathfinder(j);
          if (!target) continue;
          const { range } = target.pathfinder;
          const pathfinder = { ...target.pathfinder };
          if (j === range[0]) {
            pathfinder['name'] = column[j];
          }
          const keyName = _infoIdx[j - range[0]];
          if (keyName) {
            pathfinder[keyName] = column[j];
          }
          pathfinders[target.idx] = pathfinder;
        }
      }
    }

    for (let idx in pathfinders) {
      const pathfinder = { ...pathfinders[idx] };
      const elements = [];
      for (let i = pathfinder.range[0] + 2; i < pathfinder.range[1]; i++) {
        if (!grid[_elementColIdx.name][i]) break;
        elements.push({
          name: grid[_elementColIdx.name][i],
          rolloverText: grid[_elementColIdx.rolloverText][i],
          filters: grid[_elementColIdx.filters][i],
          image: parseImageUrl(grid[_elementColIdx.image][i]),
        });
      }
      pathfinder.elements = elements;

      const statements = [];
      for (let i = pathfinder.range[0] + 2; i < pathfinder.range[1]; i++) {
        if (!grid[_statementColIdx.statement][i]) break;
        statements.push({
          statement: grid[_statementColIdx.statement][i],
          rollover1: grid[_statementColIdx.rollover1][i],
          learning1: grid[_statementColIdx.learning1][i],
          rollover2: grid[_statementColIdx.rollover2][i],
          learning2: grid[_statementColIdx.learning2][i],
          rollover3: grid[_statementColIdx.rollover3][i],
          learning3: grid[_statementColIdx.learning3][i],
          element: grid[_statementColIdx.element][i],
        });
      }
      pathfinder.statements = statements;
      pathfinders[idx] = pathfinder;
    }

    // handle learnings
    const learnings = [];
    const ws2 = wb.Sheets[wb.SheetNames[1]];
    if (ws2) {
      const range2 = utils.decode_range(ws2['!ref']);
      const grid2 = [];
      for (let rowNum = range2.s.r; rowNum <= range2.e.r; rowNum++) {
        i += 1;
        const row = [];
        // Parse each row of the column
        for (let colNum = range2.s.c; colNum <= range2.e.c; colNum++) {
          const cellAddress = utils.encode_cell({ r: rowNum, c: colNum });

          const cellData = ws2[cellAddress];
          let cellValue = cellData?.v || cellData?.f;
          if (row.length === _learningColIdx.url && cellData?.l?.Target) {
            cellValue = {
              url: cellData?.l?.Target,
              urlShortName: cellData?.v || cellData?.f,
            };
          }
          row.push(cellValue);
        }
        grid2.push(row);
      }
      for (let i = 1; i < grid2.length; i++) {
        learnings.push({
          name: grid2[i][_learningColIdx.name],
          url: typeof grid2[i][_learningColIdx.url] === "string" ? grid2[i][_learningColIdx.url] : grid2[i][_learningColIdx.url]?.url,
          urlShortName: typeof grid2[i][_learningColIdx.url] === "object" ? grid2[i][_learningColIdx.url]?.urlShortName : undefined,
          icon: grid2[i][_learningColIdx.icon],
          type: grid2[i][_learningColIdx.type],
          comment: grid2[i][_learningColIdx.comment],
          tags: grid2[i][_learningColIdx.tags] ? String(grid2[i][_learningColIdx.tags]) : undefined,
        })
      }
    }
    return {
      pathfinders,
      learnings,
    }
  }
  saveDataToFileOldFormat = (data: PathfinderImportItem[]) => {
    const numOfCol = 15;
    const numOfRows = data.map(i => Math.max(6, i.elements?.length + 2, i.statements?.length + 2));
    const numOfRow = numOfRows.reduce((a, b) => a + b, 0);
    const worksheet = utils.aoa_to_sheet(Array.from({ length: numOfRow }, () => new Array(numOfCol).fill('')));

    const editCell = (r, c, values, styles = undefined) => {
      const cellAddress = utils.encode_cell({ r, c });
      worksheet[cellAddress] = {
        ...values,
        s: { wrapText: true, ...styles },
      };
    }
    const merges = [];

    const columnWidths = [
      { wch: 30 },
      { wch: 10 },
      { wch: 35 },
      { wch: 20 },
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 40 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
    ];

    worksheet['!cols'] = columnWidths;
    worksheet['!rows'] = new Array(numOfRow).fill({ hpx: 20 });
    numOfRows.map((maxRows, idx) => {
      const pathfinder = data[idx] as PathfinderImportItem;
      const startRow = [...numOfRows].splice(0, idx).reduce((a, b) => a + b, 0) + 1 * idx;
      const endRow = startRow + maxRows - 1;
      // logo
      editCell(startRow, 0, {
        v: 'Logo',
      }, { textDecoration: 'Bold' });
      merges.push(
        { s: { r: startRow + 1, c: 0 }, e: { r: endRow, c: 0 } },
      );
      editCell(startRow + 1, 0, {
        // f: pathfinder.logo ? `IMAGE("${pathfinder.logo}", 1)` : '',
        v: pathfinder.logo,
      });
      // info
      Object.keys(infoIdx).forEach((key) => {
        editCell(startRow + infoIdx[key].idx, 1, {
          v: infoIdx[key].label || '',
        }, { textDecoration: 'Bold' });
        editCell(startRow + infoIdx[key].idx, 2, {
          v: pathfinder[key] || '',
        });
      })
      // elements
      editCell(startRow, 3, {
        v: 'Screen Elements',
      }, { textDecoration: 'Bold' });
      Object.keys(elementColIdx).forEach((key) => {
        editCell(startRow + 1, elementColIdx[key].idx, {
          v: elementColIdx[key].label || '',
        }, { textDecoration: 'Bold' });
      })
      pathfinder.elements?.forEach((element, idx) => {
        Object.keys(elementColIdx).forEach((key) => {
          // const value = key === 'image' ? {
          //   f: element[key] ? `IMAGE("${element[key]}", 1)` : '',
          // } : {
          //   v: element[key] || '',
          // }
          editCell(startRow + 2 + idx, elementColIdx[key].idx, {
            v: element[key] || '',
          });
        })
      })
      // statements
      editCell(startRow, 7, {
        v: 'Work statements',
      }, { textDecoration: 'Bold' });
      Object.keys(statementColIdx).forEach((key) => {
        editCell(startRow + 1, statementColIdx[key].idx, {
          v: statementColIdx[key].label || '',
        }, { textDecoration: 'Bold' });
      })
      pathfinder.statements?.forEach((element, idx) => {
        Object.keys(statementColIdx).forEach((key) => {
          editCell(startRow + 2 + idx, statementColIdx[key].idx, {
            v: element[key] || '',
          });
        })
      })
    })
    worksheet['!merges'] = merges;
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = write(workbook, { type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelBlob, 'exported-pathfinders.xlsx');
  }

  saveArrayToFile = (data: any[], options?: any) => {
    const formatedData = data.map(i => {
      const newObj = {};
      Object.keys(i).forEach(key => {
        newObj[fieldNames[key] || key] = i[key];
      })
      return newObj;
    })
    const worksheet = utils.json_to_sheet(formatedData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = write(workbook, { type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelBlob, options?.fileName || 'exported.xlsx');
  }
}

export default new ExportHelper();
